<script>
import { defineComponent } from 'vue'

export default defineComponent({
  computed: {
    fetching() {
      return this.$store.state.business.fetchingListChannels
    },
    channels() {
      return this.$store.state.business.listChannels || []
    },
    channelId() {
      return +this.$route.params.id
    },
    channel() {
      return this.channels.find(channel => channel.id === this.channelId)
    },
  },
})
</script>

<template>
  <div>
    <a-result v-if="!fetching && !channel" status="404" title="404" sub-title="Sorry, the page you visited does not exist.">
      <template #extra>
        <a-button type="primary" @click.prevent="$router.back()">
          Kembali
        </a-button>
      </template>
    </a-result>
    <RouterView v-else />
  </div>
</template>
